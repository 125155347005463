<template>
    <div>
      <h1>About Us</h1>
      <!-- Add your content here -->
    </div>
  </template>
  
  <script>
  export default {
    // Add your component logic here
  };
  </script>
  