<template>
  <div id="app">
    <div class="business-card">
      <h1 class="name">{{ name }}</h1>
      <p class="title">Research Associate</p>
      <p class="organization">Forschungsstelle für Energiewirtschaft e.V.</p>
      <p class="contact">
        <strong>Email:</strong> <a href="mailto:jhawran@ffe.de">{{ email }}</a>
        <br />
        <strong>Phone:</strong> <a href="tel:+4918915812179">{{ phone }}</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: 'Jeremias Hawran M.Sc',
      email: 'jhawran@ffe.de',
      phone: '+49 (0)89 158121-79'
    };
  }
};
</script>

<style>
#app {
  font-family: Arial, sans-serif;
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  height: 100vh; /* Set the height of the container to fill the viewport */
  background-color: #f9f9f9; /* White background for the entire page */
}

.business-card {
  background-color: #fff; /* White background for the card */
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
}

.name {
  font-size: 28px;
  margin-bottom: 10px;
  color: #333;
}

.title {
  font-size: 20px;
  margin-bottom: 10px;
  color: #555;
}

.organization {
  font-size: 18px;
  margin-bottom: 20px;
  color: #666;
}

.contact {
  font-size: 16px;
  color: #444;
  line-height: 1.6;
}

.contact a {
  color: #007BFF;
  text-decoration: none;
  margin-right: 10px;
}

.contact a:hover {
  text-decoration: underline;
}
</style>
