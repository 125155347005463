<template>
  <div class="business-card">
    <h1 class="name">{{ name }}</h1>
    <p class="title">{{ title }}</p>
    <p class="organization">{{ organization }}</p>
    <p class="contact">
      Mail: <a href="mailto:jhawran@ffe.de">{{ email }}</a>
      <br />
      Phone: <a href="tel:+4918915812179">{{ phone }}</a>
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: 'Jeremias Hawran M.Sc.',
      title: 'Research Associate',
      organization: 'Forschungsstelle für Energiewirtschaft e.V.',
      email: 'jhawran@ffe.de',
      phone: '+49 (0)89 158121-79'
    };
  }
};
</script>

<style scoped>
.business-card {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
}

.name {
  font-size: 30px;
  margin-bottom: 5px;
  color: #333;
}

.title {
  font-size: 20px;
  margin-bottom: 10px;
  color: #555;
}

.organization {
  font-size: 18px;
  margin-bottom: 15px;
  color: #666;
}

.contact {
  font-size: 16px;
  color: #333;
}

.contact a {
  color: #007BFF;
  text-decoration: none;
  margin-right: 10px;
}

.contact a:hover {
  text-decoration: underline;
}
</style>
